import React from 'react';
import clsx from 'clsx';
import query from '../../utils/query';
import './AcceptDrop.css';
/*
There are 4 postures:
1. normal. Either there's no dragging in progress or it happens outside the window
2. bad. The dragged payload will not be accepted
3. approach. The dragged payload is fine, but it's not yet inside the heliport
4. good. The dragged payload is fine and is inside the heliport
 */

// =====================================================================================================================
//  D E C L A R A T I O N S
// =====================================================================================================================
const POSTURE_NORMAL = 'POSTURE_NORMAL';
const POSTURE_BAD = 'POSTURE_BAD';
const POSTURE_APPROACH = 'POSTURE_APPROACH';
const POSTURE_GOOD = 'POSTURE_GOOD';

// =====================================================================================================================
//  C O M P O N E N T
// =====================================================================================================================
class AcceptDrop extends React.PureComponent {
  state = {
    posture: POSTURE_NORMAL,
  };
  ref = React.createRef();

  render() {
    const { className, isAlwaysOn, message, children } = this.props;
    return (
      <div
        className={clsx('root', this.state.posture, isAlwaysOn && 'isAlwaysOn', className)}
        dangerouslySetInnerHTML={
          this.state.posture === POSTURE_APPROACH && message ? { __html: message } : null
        }
        ref={this.ref}
      >
        {children}
      </div>
    );
  }

  componentDidMount() {
    this.toggle(true);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { disabled } = this.props;
    if (prevProps.disabled !== disabled) {
      this.toggle(!disabled);
    }
  }

  componentWillUnmount() {
    this.toggle(false);
  }

  // -----------------------------------------------------------------------------------------------------------------
  //  I N T E R N A L
  // -----------------------------------------------------------------------------------------------------------------
  /**
   *
   */
  toggle = (isActive) => {
    if (isActive) {
      document.addEventListener('dragover', this.onDocumentDragOver);
      document.addEventListener('dragleave', this.onDocumentDragLeave);
      document.addEventListener('drop', this.onDocumentDrop);
    } else {
      document.removeEventListener('dragover', this.onDocumentDragOver);
      document.removeEventListener('dragleave', this.onDocumentDragLeave);
      document.removeEventListener('drop', this.onDocumentDrop);
    }
  };

  /**
   *
   */
  onDocumentDragOver = (event) => {
    event.preventDefault();
    this.setState({ posture: this.getPosture(event) });
  };

  /**
   *
   */
  onDocumentDragLeave = (event) => {
    event.preventDefault();
    // The following check is needed because `leave` is triggered even when you're still inside the viewport.
    if (
      event.clientX <= 0 ||
      event.clientY <= 0 ||
      event.clientX >= window.innerWidth ||
      event.clientY >= window.innerHeight
    ) {
      this.setState({ posture: POSTURE_NORMAL });
    }
  };

  /**
   *
   */
  onDocumentDrop = (event) => {
    event.preventDefault();
    const posture = this.getPosture(event);
    this.setState({ posture: POSTURE_NORMAL }); // must be placed before triggering onDrop to avoid leaks
    const { accepts, onDrop } = this.props;
    if (posture === POSTURE_GOOD) {
      const output = [];
      const { dataTransfer } = event;
      if (accepts.includes('text/plain')) {
        const text = dataTransfer.getData('text/plain');
        if (text) {
          output.push(text);
        }
      }
      console.log(accepts, dataTransfer.files);
      for (const file of dataTransfer.files) {
        if (accepts.includes(file.type)) {
          output.push(file);
        }
      }
      if (output.length) {
        onDrop(output);
      }
    }
  };

  /**
   *
   */
  getPosture = (event) => {
    const dialog = query('body > [role="dialog"]')[0];
    if (dialog) {
      if (!isInside(this.ref.current, dialog)) {
        return POSTURE_NORMAL;
      }
    }
    const { accepts } = this.props;
    if (checkAccepts(event.dataTransfer, accepts)) {
      if (isInside(event.target, this.ref.current)) {
        return POSTURE_GOOD;
      } else {
        return POSTURE_APPROACH;
      }
    } else {
      return POSTURE_BAD;
    }
  };
}

// =====================================================================================================================
//  H E L P E R S
// =====================================================================================================================
/**
 *
 */
const isInside = (target, parent) => {
  let current = target;
  while (current && current !== parent) {
    current = current.parentNode;
  }
  return current === parent;
};

/**
 *
 */
const checkAccepts = (dataTransfer, accepts) => {
  for (const item of dataTransfer.items) {
    if (accepts.includes(item.type)) {
      return true;
    }
  }
  return false;
};

// =====================================================================================================================
//  D E F I N I T I O N
// =====================================================================================================================

export default AcceptDrop;
